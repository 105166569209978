import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  availableLocales: PropTypes.objectOf(
    PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
  ).isRequired,
  chooseLanguageString: PropTypes.string.isRequired,
  currentLocale: PropTypes.string.isRequired,
  dir: PropTypes.string,
  targetPathTemplate: PropTypes.string.isRequired,
};

const LanguageSelector = ({
  availableLocales,
  chooseLanguageString,
  currentLocale,
  dir = null,
  targetPathTemplate,
}) => {
  const handleSelect = ({ target: { value } }) => {
    window.location.pathname = targetPathTemplate.replace('__locale__', value);
  };

  return (
    <div className="language-selector">
      <div className="language-selector__menu">
        <div className="language-selector__field">
          <label dir={dir}>
            {chooseLanguageString}:
          </label>
          <select
            onChange={handleSelect}
            value={currentLocale}
          >
            {Object.keys(availableLocales).map(key => (
              <optgroup
                key={key}
                label={key}
              >
                {/* Temporarily filter out Arabic per client request */}
                {availableLocales[key].filter(([, code]) => code !== 'ar').map(([name, code]) => (
                  <option
                    key={code}
                    value={code}
                  >
                    {name}
                  </option>
                ))}
              </optgroup>
            ))}
          </select>
        </div>
      </div>
    </div>
  );
};

LanguageSelector.propTypes = propTypes;

export default LanguageSelector;
